<template>
  <div class="signUp">
    <heads></heads>
    <div class="form_wrap">
      <div class="form_box">
        <el-form :model="form" ref="form" :rules="rules" label-width="100px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="form.sex">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="证件类型" prop="document_type" label-width="120px">
            <el-select v-model="form.document_type" placeholder="请选择证件类型">
              <el-option label="居民身份证" value="1"></el-option>
              <el-option label="港澳居民往来内地通行证" value="2"></el-option>
              <el-option label="护照" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码" prop="card" label-width="120px">
            <el-input v-model="form.card"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone" label-width="120px">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email" label-width="120px">
            <el-input v-model="form.email"></el-input>
          </el-form-item>

          <el-form-item label="社会身份" I prop="social_identity" label-width="120px">
            <el-radio-group v-model="form.social_identity" @change="socialIdentityChange">
              <el-radio label="1">已工作</el-radio>
              <el-radio label="2">学生</el-radio>
              <el-radio label="3">其它</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item class="social_identity_unit" label="请填写所在单位" prop="work_unit" label-width="120px">
            <el-input v-model="form.work_unit"></el-input>
          </el-form-item>

          <el-form-item class="social_identity_school" label="请填写所在学校" prop="work_unit_school" label-width="120px">
            <el-input v-model="form.work_unit_school"></el-input>
          </el-form-item>

          <el-form-item label="工作单位类型" label-width="180px" prop="work_type">
            <el-select v-model="form.work_type" placeholder="请选择工作单位类型">
              <el-option label="幼儿园" value="1"></el-option>
              <el-option label="小学" value="2"></el-option>
              <el-option label="中学" value="3"></el-option>
              <el-option label="高校" value="4"></el-option>
              <el-option label="其他" value="5"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="您所在省" label-width="120px" prop="province">
            <el-input v-model="form.province"></el-input>
          </el-form-item>

          <el-form-item label="您所在市" label-width="120px" prop="city">
            <el-input v-model="form.city"></el-input>
          </el-form-item>
          <el-form-item label="您所在区" label-width="120px" prop="district">
            <el-input v-model="form.district"></el-input>
          </el-form-item>

          <el-form-item label="是否是课题试验区或示范基地" label-width="300px" prop="is_project">
            <el-radio-group v-model="form.is_project">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="职务" prop="post">
            <el-input v-model="form.post"></el-input>
          </el-form-item>
          <el-form-item label="职称" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="授课专业" prop="lecturing_specialty" label-width="120px">
            <el-input v-model="form.lecturing_specialty"></el-input>
          </el-form-item>
          <el-form-item label="是否参加过培训并获得证书" label-width="280px" prop="is_join_train">
            <el-radio-group v-model="form.is_join_train" @change="isJoinTrain">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="certificate_no" label="证书编号">
            <el-input v-model="form.certificate_no"></el-input>
          </el-form-item>
          <el-form-item label="选择授课等级" label-width="150px" prop="train_stage_id">
            <el-radio-group v-model="form.train_stage_id">
              <el-radio label="1">初级</el-radio>
              <el-radio label="2">中级</el-radio>
              <el-radio label="3">高级</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" label-width="180px">
            <el-checkbox-group v-model="form.type">
              <el-checkbox label="已认真阅读报报名须知" name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button class="btn_form_submit" type="primary" @click="submitForm()"></el-button>
          </el-form-item>
        </el-form>
        <div>
          <span>咨询QQ:2462234108</span>
          <span>咨询电话:18515070887 / 18515070806 </span>
        </div>
        <u class="xiazai">
          <a :href="articleh.enclosure" target="_blank">下载链接：“全国中小学传统文化骨干教师”培训报名信息表”,</a>
        </u>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import $store from "../../store";
import { getArticle } from "@api/public";
import { trainSigup, getUserInfo } from "@api/user";
export default {
  components: {
    heads,
    foot,
  },
  data() {
    return {
      articleh: [],
      form: {
        name: "",
        sex: "",
        document_type: "",
        card: "",
        phone: "",
        email: "",
        work_type: "",
        is_project: "",
        post: "",
        title: "",
        lecturing_specialty: "",
        is_join_train: "",
        certificate_no: "",
        train_stage_id: "",
        province: "",
        city: "",
        district: "",
        type: false,
        social_identity: "",
        work_unit: "",
        work_unit_school: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
        document_type: [
          {
            required: true,
            message: "请选择证件类型",
            trigger: "change",
          },
        ],
        card: [
          {
            required: true,
            message: "请输入证件号",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "请选择所在地区",
            trigger: "blur",
          },
        ],

        work_type: [
          {
            required: true,
            message: "请选择工作单位类型",
            trigger: "change",
          },
        ],
        is_project: [
          {
            required: true,
            message: "请选择是否为示范校",
            trigger: "change",
          },
        ],
        post: [
          {
            required: false,
            message: "请输入职务",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: false,
            message: "请输入职称",
            trigger: "blur",
          },
        ],
        lecturing_specialty: [
          {
            required: false,
            message: "请输入授课专业",
            trigger: "blur",
          },
        ],
        is_join_train: [
          {
            required: true,
            message: "请选择是否参加过培训",
            trigger: "change",
          },
        ],
        train_stage_id: [
          {
            required: true,
            message: "请选择培训级别",
            trigger: "change",
          },
        ],
        province: [
          {
            required: true,
            message: "请填写所在省份",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "请填写所在市",
            trigger: "blur",
          },
        ],
        district: [
          {
            required: true,
            message: "请填写所在区",
            trigger: "blur",
          },
        ],
        work_unit: [
          {
            required: false,
            message: "请填写所在单位",
            trigger: "blur",
          },
        ],
        work_unit_school: [
          {
            required: false,
            message: "请填写所在学校",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted: function () {
    let that = this;
    getArticle({ id: 8 }).then((res) => {
      that.$set(that, "articleh", res.data);
    });
  },
  methods: {
    submitForm() {
      let that = this;
      that.$refs.form.validate(function (valid) {
        if (valid) {
          if (!that.form.type) {
            that.$dialog.error("请勾选阅读须知！");
          } else {
            trainSigup(that.form)
              .then(function (data) {
                that.$dialog.success(data.message);
                that.$store.commit(
                  "LOGIN",
                  data.token,
                  dayjs(data.expires_time)
                );
                if (!$store.state.app.userInfo) {
                  getUserInfo().then((res) => {
                    that.$store.commit("UPDATE_USERINFO", res.data);
                    const backUrl = cookie.get(BACK_URL) || "/";
                    cookie.remove(BACK_URL);
                    that.$router.replace({ path: backUrl });
                  });
                }
              })
              .catch(function (e) {
                that.$dialog.error(e.msg);
              });
          }
        } else {
          return false;
        }
      });
    },
    socialIdentityChange(socialIdentityValue) {
      if (socialIdentityValue == 1) {
        $(".social_identity_unit").show();
        $(".social_identity_school").hide();
      } else if (socialIdentityValue == 2) {
        $(".social_identity_unit").hide();
        $(".social_identity_school").show();
      } else {
        $(".social_identity_unit").hide();
        $(".social_identity_school").hide();
      }
    },
    isJoinTrain(isJoinTrainValue) {
      if (isJoinTrainValue == 1) {
        $(".certificate_no").show();
      } else {
        $(".certificate_no").hide();
      }
    },
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
.xiazai {
  font-size: 16px;
  position: relative;
  left: 430px;
  top: -30px;
}

.el-radio__inner {
  width: 29px;
  height: 29px;
}

.el-form-item {
  margin-bottom: 30px;
}

.el-form-item__label,
.el-radio__label {
  font-size: 20px;
}

.btn_left {
  width: 222px;
  background: transparent;
  border: 0;
  height: 60px;
  position: relative;
  top: 150px;
  left: -72px;
}

.el-button:focus {
  background: transparent;
  border: 0;
}

.btn_form_submit {
  width: 306px;
  height: 60px;
  position: relative;
  background: transparent;
  border: 0;
  top: 150px;
  left: 10px;
  background: url(../../assets/images/signup_form_btn.png) center center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.el-button:hover {
  background: url(../../assets/images/signup_form_btn.png) center center;
  background-size: 100%;
  background-repeat: no-repeat;
  border: 0;
}

.el-input__inner {
  width: 290px;
  height: 29px;
  border-radius: 29px;
  box-sizing: border-box;
  border: 1px solid #e7e1de;
}

.signUp>.form_wrap {
  width: 100%;
  height: 2026px;
  background: url(../../assets/images/baomingBg.jpg);
  background-size: cover;
  background-position: center 0;
}

.signUp .form_box {
  width: 1019px;
  height: 1720px;
  position: relative;
  top: 149px;
  margin: 0 auto;
  padding-left: 150px;
  padding-top: 220px;
  box-sizing: border-box;
}

.form_box>div {
  position: relative;
  top: -10px;
  left: -150px;
}

.form_box>div>span {
  font-size: 16px;
  font-family: 幼圆;
  margin-right: 30px;
}

.social_identity_unit,
.social_identity_school,
.certificate_no {
  display: none;
}
</style>